/**
 * Bootstrap
 */
const bootstrap = require('bootstrap')

/**
 * Font Awesome
 */
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

/**
 * Alpine
 */
import 'alpinejs';

/**
 * Chart.js
 */

// Base chart.js
import Chart from 'chart.js';

// Plugin for labels
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Chart.plugins.unregister(ChartDataLabels);

/**
 * Bootstrap dropdowns
 */
 var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
 var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
	return new bootstrap.Dropdown(dropdownToggleEl)
 })

 /**
  * Bootstrap tooltips
  */
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	return new bootstrap.Tooltip(tooltipTriggerEl)
})
